import React, { useEffect, useMemo } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ConfigProvider, ThemeConfig } from 'antd'

import { getQueryGender, getQueryValue } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'

import 'src/i18n/config'
import SwipePage from './pages/swipe'
import Loader from './components/Loader'

import history from './utils/history'
import { DEBUG_MODE, ENVIRONMENTS } from './settings/global'
import { GetLookCall } from './store/actions/look'
import Error from './components/Error'
import 'yet-another-react-lightbox/styles.css'
import LayoutContainer from './containers/layout/layout'
import { disableAnalytics, enableAnalytics } from './utils/tracking'
import { getFilteredGarmentsForWishlist, getGarmentsFromWishlist } from './utils/wishlist'
import useCustomGetGarments from './utils/custom-getGarments-hook'
import { getUserModel } from './utils/localStorageHelpers'
import { toggleModelModal } from './store/slices/layoutSlice'
import { useGetCompanyMutation } from './store/api/api-profile'
import { useAppSelector } from './store'
import { addFavoriteGarments, changeStorageBaseKey } from './store/slices/favoritesSilce'
import useCustomGetModels from './utils/custom-getModels-hook'

const App: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const company = useAppSelector((state) => state.profile?.company)
    const error = useAppSelector((state) => state.error)
    const look = useAppSelector((state) => state.look.current)

    const [getGarmentsTrigger] = useCustomGetGarments()
    const [getCompanyTrigger] = useGetCompanyMutation()
    const [getModelsTrigger] = useCustomGetModels()

    const styleOverride = !DEBUG_MODE ? null : ``

    const configTheme = useMemo(() => {
        // ---- Primary color surchargee en url ----
        let primaryColorQueryParam = getQueryValue('primary_color')

        // ---- Edge case from old demo ----
        if (typeof primaryColorQueryParam === 'string' && primaryColorQueryParam.match(/^0+$/)) {
            primaryColorQueryParam = 'black'
        }

        // ---- We use the in priority the queryparam then the config value if it exists or we default to the antd blue ----
        const primaryColor =
            primaryColorQueryParam || company?.custom_colors?.primary_color || '#1677FF'

        const root = document.documentElement
        root?.style.setProperty('--scrollbar-color', primaryColor)
        root?.style.setProperty('--primary-color', primaryColor)

        const theme: ThemeConfig = {
            token: {
                colorPrimary: primaryColor,
                fontFamily: 'inherit',
                colorLink: primaryColor,
                borderRadius: 0,
            },
            components: {
                Checkbox: {
                    borderRadiusSM: 0,
                    colorPrimaryHover: 'white',
                    colorPrimaryBorder: 'var(--primary-color)',
                    colorPrimary: 'white',
                    colorBorder: 'var(--primary-color)',
                    colorWhite: 'var(--primary-color)',
                },
                Button: {
                    textHoverBg: 'transparent',
                    primaryShadow: 'none',
                    defaultBorderColor: 'var(--primary-color)',
                    defaultColor: 'rgba(0, 0, 0, 0.88)',
                    colorText: 'var(--primary-color)',
                    paddingInline: 16,
                    paddingBlock: 4,
                    fontSizeLG: 14,
                    defaultHoverBg: 'transparent',
                    defaultActiveBg: 'transparent',
                    colorLink: 'black',
                    colorLinkHover: 'black',
                },
                Select: {
                    colorBorder: 'transparent',
                    colorPrimary: 'transparent',
                    colorPrimaryHover: 'transparent',
                    optionSelectedBg: '#ebebf0',
                },
                Card: {
                    colorBorderSecondary: 'transparent',
                    paddingLG: 8,
                },
                Modal: {
                    borderRadiusLG: 0,
                },
                Collapse: {
                    headerBg: 'white',
                    lineWidth: 0,
                    fontWeightStrong: 500,
                    headerPadding: 10,
                    contentBg: 'white',
                },
                Tabs: {
                    cardPadding: '0px 16px',
                },
                Input: {
                    addonBg: 'white',
                    colorBorder: 'var(--primary-color)',
                    controlOutlineWidth: 1,
                },
            },
        }

        if (company?.override_antd_theme) {
            const parsedConfigTheme = JSON.parse(company?.override_antd_theme)
            Object.keys(parsedConfigTheme).forEach((configKey) => {
                if (configKey === 'token') {
                    theme.token = { ...theme.token, ...parsedConfigTheme[configKey] }
                }

                if (configKey === 'components') {
                    const configComponentsTheme = parsedConfigTheme[configKey]
                    Object.keys(configComponentsTheme).forEach((componentKey) => {
                        if (theme.components[componentKey]) {
                            return (theme.components[componentKey] = {
                                ...theme.components[componentKey],
                                ...configComponentsTheme[componentKey],
                            })
                        }

                        return (theme.components[componentKey] =
                            configComponentsTheme[componentKey])
                    })
                }
            })
        }

        return theme
    }, [company])

    useEffect(() => {
        getCompanyTrigger()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            if (inIframe()) {
                const messageData = {
                    pathname: location.pathname,
                }
                // ---- Remettre les champs de store/index ----
                for (const key of ['experience_id', 'look_id', 'type']) {
                    const value = getQueryValue(key)
                    if (value !== null) {
                        messageData[key] = value
                    }
                }
                sendIframeMessage('veesual_location', messageData)
            }
        })
        // eslint-disable-next-line
    }, [])

    // ---- Chargement des favoris en fonction de l'override Gender ----
    useEffect(() => {
        if (company) {
            // ---- Toggle Chose Model Modal if we have the setting and no model in local storage ----
            if (company.enable_model_on_start && !getUserModel()) {
                // TODO: add this as config param
                if (company.internal === 'adoremeus') {
                    if (getQueryValue('garment_id')) {
                        dispatch(toggleModelModal())
                    }
                } else {
                    dispatch(toggleModelModal())
                }
            }

            // ---- Traitement du gender ----
            const genderValue = getQueryGender(company.genders)
            if (genderValue) {
                dispatch(changeStorageBaseKey(genderValue))
            }

            // ---- Loading Models and look----
            dispatch(GetLookCall(getQueryValue('look_id')))
            getModelsTrigger()

            // ---- Recuperation de la wishlist externe si besoin ----
            getGarmentsFromWishlist((success: boolean, params: any = null) => {
                if (params) {
                    getGarmentsTrigger(params)
                        .unwrap()
                        .then((resp) => {
                            dispatch(
                                addFavoriteGarments(getFilteredGarmentsForWishlist(resp.items))
                            )
                        })
                }
            })
        }
        // eslint-disable-next-line
    }, [company])

    useEffect(() => {
        // ---- L'experience est ready ----
        if (inIframe() && company) {
            sendIframeMessage('veesual_ready', { id: company.id })
        }

        // ---- Gestion des events d'analytics ----
        const enableOrDisableAnalytics = (e) => {
            if (typeof e.data !== 'string') {
                return
            }
            const splitted = e.data.split(':')
            const messageName = splitted[0]
            if (messageName === 'veesual_enable_analytics') {
                enableAnalytics()
            } else if (messageName === 'veesual_disable_analytics') {
                disableAnalytics()
            }
        }
        window.addEventListener('message', enableOrDisableAnalytics, false)
        return () => {
            window.removeEventListener('message', enableOrDisableAnalytics)
        }
        // eslint-disable-next-line
    }, [company])

    // ---- FIX IN DEV TO PREVENT ERROR POPIN WHEN RESIZING ----
    useEffect(() => {
        if (ENVIRONMENTS.development) {
            window.addEventListener('error', (e) => {
                if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    const resizeObserverErrDiv = document.getElementById(
                        'webpack-dev-server-client-overlay-div'
                    )
                    const resizeObserverErr = document.getElementById(
                        'webpack-dev-server-client-overlay'
                    )
                    if (resizeObserverErr) {
                        resizeObserverErr.setAttribute('style', 'display: none')
                    }
                    if (resizeObserverErrDiv) {
                        resizeObserverErrDiv.setAttribute('style', 'display: none')
                    }
                }
            })
        }
    }, [])

    const domain = getQueryValue('domain')
    const fontCss =
        domain == 'marinehenrion.com'
            ? './marinehenrion/font.css'
            : domain == 'christmas.com'
            ? './christmas/font.css'
            : domain == 'farfetch.com'
            ? './farfetch/font.css'
            : domain == 'facilenfil.fr'
            ? './facilenfil/font.css'
            : domain && domain.match(/^lululemon/)
            ? './lululemon/spinner.css'
            : domain == 'jules.com'
            ? './jules/font.css'
            : domain && domain.match(/^eileenfisher/)
            ? './eileenfisher/spinner.css'
            : null

    if (error.message) {
        return <Error />
    }

    return (
        <ConfigProvider theme={configTheme}>
            {(!company || !look) && !error.message ? (
                <div className='App--loading'>
                    {fontCss && <link rel='stylesheet' href={fontCss} />}
                    <Loader />
                </div>
            ) : (
                <div className='App'>
                    <div id='referenceElement' />
                    {(styleOverride || company.override_style) && (
                        <style
                            dangerouslySetInnerHTML={{
                                __html: styleOverride || company.override_style,
                            }}
                        ></style>
                    )}

                    {fontCss && <link rel='stylesheet' href={fontCss} />}
                    <Router history={history}>
                        <Switch>
                            <LayoutContainer>
                                <Route exact path='/'>
                                    <Redirect to={'/swipe' + window.location.search} />
                                </Route>
                                <Route exact path='/catalog'>
                                    <Redirect to={'/swipe' + window.location.search} />
                                </Route>
                                <Route exact path='/product'>
                                    <Redirect to={'/swipe' + window.location.search} />
                                </Route>
                                <Route exact path='/model'>
                                    <Redirect to={'/swipe' + window.location.search} />
                                </Route>
                                <Route exact path='/favorites'>
                                    <Redirect to={'/swipe' + window.location.search} />
                                </Route>
                                <Route exact path='/swipe'>
                                    {company?.enable_swipe ? (
                                        <SwipePage />
                                    ) : (
                                        <Redirect to={'/' + window.location.search} />
                                    )}
                                </Route>
                            </LayoutContainer>
                        </Switch>
                    </Router>
                </div>
            )}
        </ConfigProvider>
    )
}

export default App
