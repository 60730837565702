import { SearchOutlined } from '@ant-design/icons'
import { Input, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'src/store'
import { getAllGarments, getCurrentActiveFiltersForType } from 'src/store/slices/databaseSlice'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import useCustomTranslation from 'src/utils/translation'

// ---- Search bar that filters the garments of current garment type -----
export default function SearchBarGarment() {
    const { t } = useCustomTranslation()
    const [getGarmentsTrigger, { isLoading }] = useCustomGetGarments()

    const allGarments = useAppSelector((state) => getAllGarments(state))
    const currentGarmentType = useAppSelector((state) => state.garment.type)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, currentGarmentType)
    )

    // ---- Input value ----
    const [value, setValue] = useState<string>(allGarments[currentGarmentType]?.query)

    // ---- Last timestamp value to debounce the request linked to the input ----
    const lastTimestamp = useRef<number>(null)

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // ---- Update State ----
        setValue(e.target.value)

        // ---- Get current timestamp and store it in lastTimestamp to compare after the debounce timeout ----
        const currentTimestamp = Date.now()
        lastTimestamp.current = currentTimestamp

        // ---- Timeout to not call requests too fast ----
        setTimeout(() => {
            // ---- If we typed more before debounce we discard the request ----
            if (currentTimestamp !== lastTimestamp.current) {
                return
            }

            getGarmentsTrigger({
                ...(allGarments[currentGarmentType]?.filterGarmentId
                    ? { garment_id: allGarments[currentGarmentType].filterGarmentId }
                    : null),
                type: currentGarmentType,
                page: 1,
                filter: activeFilters,
                query: e.target.value,
            })
        }, 300)
    }

    // ---- Updating the query value when we change the garment type ----
    useEffect(() => {
        setValue(allGarments[currentGarmentType]?.query)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGarmentType])

    return (
        <Input
            allowClear
            onChange={handleSearchChange}
            value={value}
            addonAfter={isLoading ? <Spin size='small' /> : <SearchOutlined />}
            placeholder={t('layout.search')}
        />
    )
}
