import { Button, Drawer, Row, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useFiltersContext } from 'src/components/LocalFiltersContext'
import FilterButton from 'src/components/button/FilterButton'
import SubHeader from 'src/components/header/SubHeader'
import { useAppSelector } from 'src/store'
import { getCustomMinusIcon, getDrawerLeftIcon, getDrawerRightIcon } from 'src/utils/icon'
import Catalog from '../catalog/Catalog'
import { trackEvent } from 'src/utils/tracking'
import {
    closeDrawer,
    closeLeftPart,
    expandDrawer,
    expandLeftPart,
    expandRightPart,
    setShowFilter,
    shrinkDrawer,
    shrinkLeftPart,
} from 'src/store/slices/layoutSlice'
import { countActiveFilters } from 'src/store/slices/databaseSlice'
import { handleCatalogScroll } from 'src/utils/scroll'
import useCustomTranslation from 'src/utils/translation'
import useTypeFunctions from 'src/utils/typeMethods-hook'
import { removeGarment } from 'src/utils/garment'
import CloseButton from 'src/components/button/CloseButton'
import ItemCounter from 'src/components/ItemCounter'
import SearchBarGarment from 'src/components/SearchBarGarment'
import { getQueryValue } from 'src/utils/query'

export default function DrawerDesktop() {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const FiltersContext = useFiltersContext()
    const { isPrimaryOrSecondaryType } = useTypeFunctions()
    const layoutSlice = useAppSelector((state) => state.layoutSlice)
    const showFilters = useAppSelector((state) => state.layoutSlice.showFilters)
    const numberActiveFilters = useAppSelector((state) => countActiveFilters(state))
    const garmentType = useAppSelector((state) => state.garment.type)
    const company = useAppSelector((state) => state.profile.company)
    const lookRequest = useAppSelector((state) => state.look.request)

    const handleFilterClick = (e, path = null) => {
        e.preventDefault()
        if (path === '/model') {
            trackEvent('Filter Clicked', { filter_type: 'model' }, 'Menu')
        } else {
            trackEvent('Filter Clicked', { catalog_type: garmentType, filter_type: 'item' }, 'Menu')
        }

        // ---- Expand the right part if it was closed so we see the filters ----
        if (!showFilters && layoutSlice.rightPart === 'none') {
            dispatch(expandRightPart())
        }
        dispatch(setShowFilter(!showFilters))
    }

    const handleExpandCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Expand Clicked',
                { expand_from: layoutSlice.drawer, expand_type: 'drawer' },
                'Catalog'
            )
            dispatch(expandDrawer())
            return
        }
        trackEvent(
            'Expand Clicked',
            { expand_from: layoutSlice.leftPart, shrink_type: 'left' },
            'Catalog'
        )

        // ---- Close filters if we set the left part to big ----
        if (showFilters && layoutSlice.leftPart === 'medium') {
            dispatch(setShowFilter(!showFilters))
        }
        dispatch(expandLeftPart())
    }

    const handleShrinkCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Shrink Clicked',
                { shrink_from: layoutSlice.drawer, shrink_type: 'drawer' },
                'Catalog'
            )
            dispatch(shrinkDrawer())
            return
        }

        trackEvent(
            'Shrink Clicked',
            { shrink_from: layoutSlice.leftPart, shrink_type: 'left' },
            'Catalog'
        )
        dispatch(shrinkLeftPart())
    }

    const handleCloseCatalog = () => {
        if (window.innerWidth / window.innerHeight < 1) {
            trackEvent(
                'Close Clicked',
                { close_from: layoutSlice.drawer, close_type: 'drawer' },
                'Catalog'
            )
            dispatch(closeDrawer())
            return
        }
        trackEvent(
            'Close Clicked',
            { close_from: layoutSlice.leftPart, close_type: 'left' },
            'Catalog'
        )

        // ---- Close filters if open when we close the catalog ----
        if (showFilters) {
            dispatch(setShowFilter(!showFilters))
        }

        dispatch(closeLeftPart())
    }

    const handleRemoveClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        trackEvent(
            'Show Type Changed',
            {
                look: lookRequest,
                show_type: garmentType,
                show_value: lookRequest[garmentType] ? 'off' : 'on',
            },
            'Catalog'
        )
        removeGarment(garmentType)
    }

    const showSubHeader = useMemo<boolean>(
        () =>
            company.garment_category_facets !== null &&
            company.garment_category_facets !== undefined &&
            company.garment_category_facets[garmentType] !== null,
        [company, garmentType]
    )

    const canRemove = useMemo<boolean>(
        () =>
            !isPrimaryOrSecondaryType(garmentType) ||
            lookRequest?.optional_types?.includes(garmentType),
        [garmentType, lookRequest, isPrimaryOrSecondaryType]
    )

    return (
        <Drawer
            destroyOnClose={true}
            placement='left'
            getContainer={false}
            open={window.innerWidth / window.innerHeight >= 1 && layoutSlice.leftPart !== 'none'}
            onClose={handleCloseCatalog}
            width={'100%'}
            mask={false}
            closable={false}
            rootClassName={`drawer--catalog ${
                layoutSlice.leftPart === 'big' ? ' drawer--width-transition' : ''
            }${canRemove ? ' drawer--catalog-remove' : ''}`}
            title={
                <div
                    className={`drawer--header${
                        !showSubHeader ? ' drawer--header-nosubheader' : ''
                    }`}
                >
                    {showSubHeader && FiltersContext && (
                        <SubHeader
                            localFilters={FiltersContext.localFilters}
                            onClick={FiltersContext.onSubHeaderClick}
                            onlyOneSubCategory={FiltersContext.onlyOneSubCategory}
                        />
                    )}
                    <Row className='drawer--header-right' justify='space-between' wrap={false}>
                        {showSubHeader && (
                            <FilterButton
                                onClick={(e) => handleFilterClick(e, '/catalog')}
                                countFilter={numberActiveFilters}
                            />
                        )}
                        <CloseButton onClick={handleCloseCatalog} />
                    </Row>
                </div>
            }
        >
            {window.innerWidth / window.innerHeight >= 1 && layoutSlice.leftPart !== 'small' && (
                <div
                    className='drawer--content'
                    onScroll={handleCatalogScroll}
                    id='drawerContentId'
                >
                    {canRemove && (
                        <div className='drawer--remove-container'>
                            <Button
                                onClick={handleRemoveClick}
                                className='button--outlined button--remove'
                                size='large'
                                icon={getCustomMinusIcon()}
                            >
                                {t('product.remove')}
                            </Button>
                        </div>
                    )}
                    {getQueryValue('enable_search') && (
                        <div className='drawer--search-container'>
                            <SearchBarGarment />
                        </div>
                    )}

                    <ItemCounter />
                    <div className='drawer--toggle-container'>
                        {layoutSlice.leftPart !== 'big' ? (
                            <Tooltip title={t('layout.expand')} trigger={'hover'}>
                                <Button
                                    icon={getDrawerRightIcon()}
                                    onClick={handleExpandCatalog}
                                    className={'button--toggle-size'}
                                    type='link'
                                />
                            </Tooltip>
                        ) : (
                            <Button
                                icon={getDrawerRightIcon()}
                                className={'button--toggle-size button--toggle-size-disabled'}
                                type='link'
                            />
                        )}
                        <Tooltip title={t('layout.shrink')} trigger={'hover'}>
                            <Button
                                icon={getDrawerLeftIcon()}
                                onClick={handleShrinkCatalog}
                                className='button--toggle-size'
                                type='link'
                            />
                        </Tooltip>
                    </div>
                    <Catalog />
                </div>
            )}
        </Drawer>
    )
}
