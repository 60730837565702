import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

interface GarmentsSlicePayload {
    type: string
    garments: API.Resp.GetGarments
    filterGarmentId?: string | null
    init?: number
    query?: string
}

interface GarmentPerType {
    current?: API.Resp.GetGarments
    currentPage?: number
    filterGarmentId?: string | null
    all?: Models.Garment[]
    insertionIndex?: number
    activeFilters: { [key: string]: string[] }
    init?: number
    query?: string
}

interface DatabaseSlice {
    allGarments: { [key: string]: GarmentPerType | null }
    prices: { [key: string]: Models.Price[] | null }
}

const initialState: DatabaseSlice = {
    allGarments: {},
    prices: {},
}

// Slice storing the garments by types and with pagination
export const databaseSlice = createSlice({
    initialState,
    name: 'databaseSlice',
    reducers: {
        addFilterForType: (
            state: DatabaseSlice,
            action: PayloadAction<{ type: string; facetKey: string; facetValue: string }>
        ) => {
            // If there are no active filters on this key we create a new array of value
            if (!state.allGarments[action.payload.type].activeFilters[action.payload.facetKey]) {
                state.allGarments[action.payload.type].activeFilters[action.payload.facetKey] = [
                    action.payload.facetValue,
                ]

                return
            }

            // If there is a value already we push the new value inside
            state.allGarments[action.payload.type].activeFilters[action.payload.facetKey].push(
                action.payload.facetValue
            )

            return
        },
        addPrices: (
            state: DatabaseSlice,
            action: PayloadAction<{ type: string; prices: Models.Price[] }>
        ) => {
            const payload = action.payload
            const statePrices = state.prices[payload.type]

            // ---- If we don't have prices in the state yet ----
            if (!statePrices || statePrices.length === 0) {
                state.prices[payload.type] = payload.prices

                return
            }

            // ---- Use Map to keep unique value per id and update the existing values ----
            const mergedArray = [...statePrices, ...payload.prices]
            const uniqueElementsMap = new Map<string, Models.Price>()
            mergedArray.forEach((item) => {
                uniqueElementsMap.set(item.garment_id, item)
            })

            // ---- Update State ----
            state.prices[payload.type] = Array.from(uniqueElementsMap.values())
        },
        removeFilterForType: (
            state: DatabaseSlice,
            action: PayloadAction<{ type: string; facetKey: string; facetValue: string }>
        ) => {
            // If there are no active filter on this key we do nothing
            if (!state.allGarments[action.payload.type].activeFilters[action.payload.facetKey]) {
                return
            }

            // if we have only 1 or 0 elem in the list we delete it
            if (
                state.allGarments[action.payload.type].activeFilters[action.payload.facetKey]
                    .length <= 1
            ) {
                delete state.allGarments[action.payload.type].activeFilters[action.payload.facetKey]

                return
            }

            // We remove the give value from the active filters on this key
            const tmpArray = state.allGarments[action.payload.type].activeFilters[
                action.payload.facetKey
            ] as string[]
            tmpArray.splice(
                tmpArray.findIndex((facetValue) => facetValue === action.payload.facetValue),
                1
            )
        },
        resetAllFilterForType: (state: DatabaseSlice, action: PayloadAction<string>) => {
            state.allGarments[action.payload].activeFilters = {}
        },
        resetAllFilters: (state: DatabaseSlice) => {
            for (const type in state.allGarments) {
                state.allGarments[type].activeFilters = {}
            }
        },
        updateAllGarmentsForType: (
            state: DatabaseSlice,
            action: PayloadAction<GarmentsSlicePayload>
        ) => {
            const stateGarments = state.allGarments[action.payload.type]
            const payloadGarments = action.payload.garments
            let newAll = payloadGarments.items
            const localInsertionIndex = state.allGarments[action.payload.type]?.insertionIndex
            if (stateGarments) {
                if (
                    payloadGarments.current_page_number > stateGarments.current.current_page_number
                ) {
                    if (localInsertionIndex && window.location.pathname === '/swipe') {
                        const lastPart = stateGarments.all.slice(localInsertionIndex)
                        const firstPart = stateGarments.all.slice(0, localInsertionIndex)
                        newAll = firstPart.concat(payloadGarments.items, lastPart)
                    } else {
                        newAll = stateGarments.all.concat(payloadGarments.items)
                    }
                }

                if (
                    payloadGarments.current_page_number ===
                        stateGarments.current.current_page_number &&
                    payloadGarments.current_page_number !== 1
                ) {
                    newAll = stateGarments.all
                        .slice(0, stateGarments.all.length - payloadGarments.items.length)
                        .concat(payloadGarments.items)
                }
            }

            state.allGarments[action.payload.type] = {
                current: payloadGarments,
                currentPage: payloadGarments.current_page_number,
                filterGarmentId: action.payload.filterGarmentId,
                all: newAll,
                activeFilters: stateGarments?.activeFilters || {},
                insertionIndex: state.allGarments[action.payload.type]?.insertionIndex,
                init: action.payload.init,
                query: action.payload.query,
            }
        },
        setCurrentPageForType: (
            state: DatabaseSlice,
            action: PayloadAction<{ type: string; page: number }>
        ) => {
            state.allGarments[action.payload.type].currentPage = action.payload.page
        },
        setInsertionIndexForType: (
            state: DatabaseSlice,
            action: PayloadAction<{ type: string; index: number }>
        ) => {
            state.allGarments[action.payload.type].insertionIndex = action.payload.index
        },
        resetAllGarmentsForType: (state: DatabaseSlice, action: PayloadAction<string>) => {
            delete state.allGarments[action.payload]
        },
        resetAll: (state: DatabaseSlice) => {
            state.allGarments = {}
        },
    },
})

// ----- Actions -----
export const {
    updateAllGarmentsForType,
    resetAll,
    resetAllGarmentsForType,
    setCurrentPageForType,
    setInsertionIndexForType,
    addFilterForType,
    removeFilterForType,
    resetAllFilterForType,
    resetAllFilters,
    addPrices,
} = databaseSlice.actions

// ----- Selector -----
export const getAllGarments = (state: RootState) => state.databaseSlice.allGarments
export const getAllGarmentsObjectForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]
export const getAllGarmentsForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]?.all
export const getCurrentGarmentsForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]?.current
export const getCurrentPageForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]?.currentPage
export const getCurrentFiltersForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]?.current.facets
export const getCurrentActiveFiltersForType = (state: RootState, type: string) =>
    state.databaseSlice.allGarments[type]?.activeFilters || {}
export const hasActiveFilters = (state: RootState) => {
    let result = false

    // We check for all garments type if there is a filter active
    for (const allGarmentsKey of Object.keys(state.databaseSlice.allGarments)) {
        if (Object.keys(state.databaseSlice.allGarments[allGarmentsKey].activeFilters).length > 0) {
            result = true
            break
        }
    }

    return result
}
export const getPricesForType = (state: RootState, type: string) => state.databaseSlice.prices[type]

export const countActiveFilters = (state: RootState) => {
    let result = 0

    // We check for all garments type if there is a filter active
    for (const allGarmentsKey of Object.keys(state.databaseSlice.allGarments)) {
        const activeFiltersObject = state.databaseSlice.allGarments[allGarmentsKey].activeFilters

        Object.keys(activeFiltersObject).forEach((filterKey) => {
            result += activeFiltersObject[filterKey].length
        })
    }

    return result
}

export default databaseSlice.reducer
